import { graphql } from 'gatsby'
import * as React from 'react'

import HirePage from '../components/pages/HirePage'
import DefaultLayout from '../layouts'

const Hire = () => (
  <DefaultLayout language="en">
    <HirePage />
  </DefaultLayout>
)

export default Hire

export const pageQuery = graphql`
  query HireQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
